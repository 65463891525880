<template>
  <component
    :class="$style.root"
    :data-has-image="image ? '' : null"
    :is="component"
  >
    <component
      :class="[$style.content, routeName ? $style.link : null]"
      :data-inverted-text="invertText ? '' : null"
      :name="routeName"
      :localizedData="routeName ? data : null"
      :is="routeName ? I18nLinkWithFallback : 'div'"
    >
      <h1 :class="$style.heading">
        {{ forcedTitle || title }}
      </h1>

      <h2
        :class="$style.subheading"
        v-if="subtitle"
      >
        {{ subtitle }}
      </h2>

      <slot />
    </component>

    <slot name="link" />

    <CoreImageComponent
      v-if="image"
      :image="image"
      :key="image?.url"
      :class="$style.image"
    />
  </component>
</template>

<script setup>
import { I18nLinkWithFallback } from '#components'

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  image: {
    type: Object,
    default: null
  },
  forcedTitle: {
    type: String,
    required: false
  },
  invertText: {
    type: Boolean,
    default: false
  },
  component: {
    type: String,
    default: 'header'
  },
  routeName: {
    type: String,
  }
})

const title = computed(() => i18nGetLocalizedTitle(props?.data))

const subtitle = computed(() => i18nGetLocalizedString(props?.data?.subtitles))

</script>

<style module lang="scss">
.root {
  padding: var(--unit--vertical) var(--unit--horizontal);

  padding-top: calc(var(--unit--vertical) * 2 - (var(--font-x-large--line-height) - var(--font-x-large--font-size)));
  padding-bottom: calc(var(--unit--vertical) * 2);

  display: flex;
  gap: var(--unit--vertical);
  flex-direction: column;
  justify-content: center;

  max-width: 100vw;

  @media (max-width: $breakpoint-m) {
    padding-top: calc(var(--unit--vertical) * 3);
    padding-bottom: calc(var(--unit--vertical) * 3 - (var(--font-x-large--line-height) - var(--font-x-large--font-size)));
  }
}

.root[data-has-image] {
  height: var(--100svh);
  position: relative;
}

.content {
  text-align: center;

  max-width: var(--container--default);
  margin: auto;

  position: relative;
  z-index: 1;
}

.root[data-has-image] .content {
  position: sticky;
  top: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;

  color: var(--color--text-on-image);

  @media (max-width: $breakpoint-m) {
    position: relative;
  }
}

.root[data-has-image]:has(:global(.lazyloaded)) .content {
  text-shadow: var(--drop-shadow--text-on-image);
}

.root[data-has-image] .content[data-inverted-text] {
}

.heading {
  composes: reset-heading font-size-x-large from global;
}

.subheading {
  composes: reset-heading font-size-large from global;
}

.link {
  composes: reset-link from global;
}

.link:hover {
  color: var(--color--yellow) !important;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: 0;

  pointer-events: none;

  --base--image--height: var(--100svh);
  --base--image--object-fit: var(--layout--page-header--image--object-fit, cover);
}

</style>
